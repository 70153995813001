export const ERROR_LOG_TYPES = {
  FATAL: 'fatal',
  ERROR: 'error',
  WARNING: 'warning',
  LOG: 'log',
  INFO: 'info',
  DEBUG: 'debug',
};

// eslint-disable-next-line no-unused-vars
export const log = (message, level = ERROR_LOG_TYPES.ERROR, ...args) => {
  // TODO: Now that Sentry is removed, we need to replace this with a new logging solution
};
