import AvayaChatInit from './AvayaChatInit.js';
import qs from 'qs';
import axios from 'axios';
import { setLocalStorage, getLocalStorage, clearLocalStorage } from './utils/AvayaChatUtils';
import { getLocaleFromURL } from './utils/LocationUtils.js';

const startChat = () => {
  // do not start chat more than once
  if (window.avaya && window.avaya.is_initialized === true) {
    return;
  }

  const localeFromURL = getLocaleFromURL();
  console.log(`Locale from URL is: ${localeFromURL}`);

  // option to pass attributes for pages that use static assets integration
  const requestAttributes =
    window.avaya && typeof window.avaya.attributes !== 'undefined' ? window.avaya.attributes : {};

  let optionalParams = {};
  if (window.avaya && window.avaya.options) {
    optionalParams = window.avaya.options;
  } else if (window.avaya_chat_options) {
    optionalParams = window.avaya_chat_options;
  }

  const url = (optionalParams?.apiEndpointsDomain || '') + '/conversation/get-chat-init-configs';
  axios.post(url, qs.stringify({ locale: localeFromURL })).then((response) => {
    let {
      // Dayana to do: put back when ready to test DWP-14175 again in stage
      isChatLite,
      triage,
      isMobile,
      teslaAssistFlag,
      useEngagementPlaceholderData,
    } = response?.data?.LDChatConfigurations;

    const chatLocalFromStorage = getLocalStorage('chat_locale');
    if (chatLocalFromStorage && chatLocalFromStorage !== localeFromURL) {
      clearLocalStorage();
    }

    setLocalStorage('chat_locale', localeFromURL);

    AvayaChatInit({
      locale: localeFromURL,
      chatLiteFlag: isChatLite,
      triagePreChat: triage,
      hideOnMobiles: !isMobile,
      teslaAssistFlag: teslaAssistFlag,
      transferToLiveAgent: false,
      useEngagementPlaceholderDataFlag: useEngagementPlaceholderData, // passed in the check availability call
      ...requestAttributes,
    });
  });
  window.avaya.is_initialized = true;
};

// expose init_chat and is_initialized to init chat asynchronously
if (!window.avaya) {
  window.avaya = {};
}
window.avaya.init_chat = startChat;
window.avaya.is_initialized = false;

document.addEventListener('DOMContentLoaded', startChat);
