import axios from 'axios';

import { getLocale, getCountry, getUICountry } from './CommonUtils';
import { hostNames } from '../Data/constants';

export const getCountryData = async () => {
  const locale = getLocale();
  let additionalData = '';
  if (['zh_CN', 'ko_KR'].indexOf(locale) > -1) {
    additionalData = '&additional=true';
  }
  const response = await axios.get(`/cua-api/drive/country-data?locale=${locale}${additionalData}`);
  return response;
};

export const getLocaleFromURL = () => {
  const langRegex = 'language';
  const countryRegex = 'country';
  const LOCALE_PATTERN_ISO639 = `(?<${langRegex}>[A-Za-z]{2})[_-](?<${countryRegex}>[A-Za-z]{2})`;

  const hostname = window.location.hostname;
  if (hostname.includes(hostNames.CN_HOSTNAME)) {
    return 'zh_cn';
  }
  if (hostname.includes(hostNames.GLOBAL_HOSTNAME) || hostname === hostNames.LOCAL_HOSTNAME) {
    const pathname = window.location.pathname;
    const localeRegex = new RegExp(`^/?` + LOCALE_PATTERN_ISO639);
    if (!localeRegex.test(pathname)) {
      return 'en_us';
    }
    let executedLocaleRegex = localeRegex.exec(pathname);
    return (
      executedLocaleRegex.groups[langRegex].toLowerCase() +
      '_' +
      executedLocaleRegex.groups[countryRegex].toLowerCase()
    );
  }
};

export const isCN = () =>
  getCountry() === 'CN' || getUICountry() === 'CN' || /tesla\.cn$/.test(location.hostname);
