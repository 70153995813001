import { createElementWithAttributes } from '../../utils/CommonUtils';

class AvayaHeader {
  constructor(avayaChatConfig) {
    this.avayaChatConfig = avayaChatConfig;
  }

  generateHeader() {
    const headerText = this.avayaChatConfig?.isTeslaAssist
      ? this.avayaChatConfig?.initializers?.chatHeaderTAS
      : this.avayaChatConfig?.initializers?.chatHeader;

    const avayaChatHeaderTextContainer = createElementWithAttributes('div', {
      class: 'tw-chat--avaya-header-container',
      children: [
        {
          tag: 'span',
          attributes: {
            class: 'tw-chat--avaya-header-text',
            textContent: headerText,
          },
        },
      ],
    });

    return avayaChatHeaderTextContainer;
  }

  setChatHeader(headerText) {
    document.getElementsByClassName('tw-chat--avaya-header-container')[0].innerHTML = headerText;
  }
}

export default AvayaHeader;
