export const chatPrefix = 'avaya-chat';
export const modalId = 'avaya-chat-modal';

// a hash-map to check traffic properties
export const trafficProperties = {
  trafficid: 0,
  trafficsource: 1,
  campaignname: 2,
};

export const pages = {
  DRIVE: 'drive',
  INVENTORY_MS: 'inventory modelS',
  INVENTORY_M3: 'inventory model3',
  INVENTORY_MX: 'inventory modelX',
  INVENTORY_MY: 'inventory modelY',
  PRODUCT_MS: 'product modelS',
  PRODUCT_M3: 'product model3',
  PRODUCT_MX: 'product modelX',
  PRODUCT_MY: 'product modelY',
  CONFIGURATOR_MS: 'configurator modelS',
  CONFIGURATOR_M3: 'configurator model3',
  CONFIGURATOR_MX: 'configurator modelX',
  CONFIGURATOR_MY: 'configurator modelY',
};

export const productInterests = {
  MS: 'Model S',
  M3: 'Model 3',
  MX: 'Model X',
  MY: 'Model Y',
};

export const hostNames = {
  LOCAL_HOSTNAME: 'localhost',
  CN_HOSTNAME: 'tesla.cn',
  GLOBAL_HOSTNAME: 'tesla.com',
};
